import * as React from "react";
import styled from "styled-components";
import Web3 from "web3";
import { convertUtf8ToHex } from "@walletconnect/utils";
// @ts-ignore
import Web3Modal from "web3modal";
// @ts-ignore
import WalletConnect from "@walletconnect/web3-provider";
// @ts-ignore
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
// @ts-ignore
import Torus from "@toruslabs/torus-embed";

import Button from "./components/Button";
import Column from "./components/Column";
import Wrapper from "./components/Wrapper";
import Modal from "./components/Modal";
import Header from "./components/Header";
import Loader from "./components/Loader";
import ModalResult from "./components/ModalResult";
//import AccountAssets from "./components/AccountAssets";
import ConnectButton from "./components/ConnectButton";

import { apiGetAccountAssets } from "./helpers/api";
import {
  hashPersonalMessage,
  recoverPublicKey,
  recoverPersonalSignature,
  formatTestTransaction,
  getChainData
} from "./helpers/utilities";
import { IAssetData } from "./helpers/types";
import { fonts } from "./styles";
import {
  ETH_SEND_TRANSACTION,
  ETH_SIGN,
  PERSONAL_SIGN,
  DAI_BALANCE_OF,
  DAI_TRANSFER
} from "./constants";
import { callBalanceOf, callTransfer } from "./helpers/web3";
import { number, string } from "prop-types";

const SLayout = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  text-align: center;
`;

const SContent = styled(Wrapper)`
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

const SContainer = styled.div`
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
`;

const SLanding = styled(Column)`
  height: 600px;
`;

const SModalContainer = styled.div`
  width: 100%;
  position: relative;
  word-wrap: break-word;
`;

const SBtn = styled.div`

`;

const SModalTitle = styled.div`
  margin: 1em 0;
  font-size: 20px;
  font-weight: 700;
`;

const SModalParagraph = styled.p`
  margin-top: 30px;
`;

// @ts-ignore
const SBalances = styled(SLanding)`
  height: 100%;
  & h3 {
    padding-top: 30px;
  }
`;

const STestButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const STestButton = styled.div`
  border-radius: 20px;
  font-size: 2em;
  height: 70px;
  width: 100%;
  max-width: 100%;

  bottom: 0;
  margin: 7rem auto 0 auto;
  background-color: rgba(255,255,255,0.9);
  color: #333;

  transition: all 0.15s ease-in-out;
  position: relative;
  line-height: 2em;
  background-image: none;
  outline: none;
  overflow: hidden;
  box-shadow: none;
  border: none;
  border-style: none;
  box-sizing: border-box;
  border: none;
  box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11),
    0 1px 3px 0 rgba(0, 0, 0, 0.08), inset 0 0 1px 0 rgba(0, 0, 0, 0.06);
  font-weight: 800;
  will-change: transform;
`;

interface IAppState {
  fetching: boolean;
  address: string;
  web3: any;
  provider: any;
  connected: boolean;
  chainId: number;
  networkId: number;
  assets: IAssetData[];
  showModal: boolean;
  pendingRequest: boolean;
  result: any | null;
  selfCount: number;
  maxCount: number;
  price: number;
  pricex: number;
}

const INITIAL_STATE: IAppState = {
  fetching: false,
  address: "",
  web3: null,
  provider: null,
  connected: false,
  chainId: 1,
  networkId: 1,
  assets: [],
  showModal: false,
  pendingRequest: false,
  result: null,
  selfCount: 1,
  maxCount: 3,
  price: 0.09,
  pricex: 90000000000000000
};

function initWeb3(provider: any) {
  const web3: any = new Web3(provider);

  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber
      }
    ]
  });

  return web3;
}

class App extends React.Component<any, any> {
  // @ts-ignore
  public web3Modal: Web3Modal;
  public state: IAppState;

  constructor(props: any) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };

    this.web3Modal = new Web3Modal({
      network: this.getNetwork(),
      cacheProvider: true,
      providerOptions: this.getProviderOptions()
    });
  }

  public componentDidMount() {
    if (this.web3Modal.cachedProvider) {
      this.onConnect();
    }
  }

  public countUp = async () =>  {
    if (this.state.selfCount < 3){
      switch (this.state.selfCount+1) {
        case 1:
          this.setState({ selfCount: 1 });
          this.setState({ pricex: 90000000000000000});
          this.setState({ price: 0.09 });
          break;
        case 2:
          this.setState({ selfCount: 2 });
          this.setState({ pricex: 180000000000000000});
          this.setState({ price: 0.18 });
          break;
        case 3:
          this.setState({ selfCount: 3 });
          this.setState({ pricex: 270000000000000000});
          this.setState({ price: 0.27 });
          break;
        default:
          break;
      }
    }
  }

  public countDown = async () =>  {
    if (this.state.selfCount > 1){
      switch (this.state.selfCount-1) {
        case 1:
          this.setState({ selfCount: 1 });
          this.setState({ pricex: 90000000000000000});
          this.setState({ price: 0.09 });
          break;
        case 2:
          this.setState({ selfCount: 2 });
          this.setState({ pricex: 180000000000000000});
          this.setState({ price: 0.18 });
          break;
        case 3:
          this.setState({ selfCount: 3 });
          this.setState({ pricex: 270000000000000000});
          this.setState({ price: 0.27 });
          break;
        default:
          break;
      }
    }
  }

  public countMax = async () =>  {
    this.setState({ selfCount: 3 });
    this.setState({ pricex: 270000000000000000});
    this.setState({ price: 0.27 });
  }

  

  public onConnect = async () => {
    const provider = await this.web3Modal.connect();

    await this.subscribeProvider(provider);

    await provider.enable();
    const web3: any = initWeb3(provider);

    const accounts = await web3.eth.getAccounts();

    const address = accounts[0];

    const networkId = await web3.eth.net.getId();

    const chainId = await web3.eth.chainId();

    await this.setState({
      web3,
      provider,
      connected: true,
      address,
      chainId,
      networkId
    });
    await this.getAccountAssets();
  };

  public subscribeProvider = async (provider: any) => {
    if (!provider.on) {
      return;
    }
    provider.on("close", () => this.resetApp());
    provider.on("accountsChanged", async (accounts: string[]) => {
      await this.setState({ address: accounts[0] });
      await this.getAccountAssets();
    });
    provider.on("chainChanged", async (chainId: number) => {
      const { web3 } = this.state;
      const networkId = await web3.eth.net.getId();
      await this.setState({ chainId, networkId });
      await this.getAccountAssets();
    });

    provider.on("networkChanged", async (networkId: number) => {
      const { web3 } = this.state;
      const chainId = await web3.eth.chainId();
      await this.setState({ chainId, networkId });
      await this.getAccountAssets();
    });
  };

  public getNetwork = () => getChainData(this.state.chainId).network;

  public getProviderOptions = () => {
    const infuraId = process.env.REACT_APP_INFURA_ID;
    const providerOptions = {
      walletconnect: {
        package: WalletConnect,
        options: {
          infuraId
        }
      },
      torus: {
        package: Torus
      },
      coinbasewallet: {
        package: CoinbaseWalletSDK,
        options: {
          appName: "Okay bears",
          infuraId
        }
      }
    };
    return providerOptions;
  };

  public getAccountAssets = async () => {
    const { address, chainId } = this.state;
    this.setState({ fetching: true });
    try {
      // get account balances
      const assets = await apiGetAccountAssets(address, chainId);

      await this.setState({ fetching: false, assets });
    } catch (error) {
      console.error(error); // tslint:disable-line
      await this.setState({ fetching: false });
    }
  };

  public toggleModal = () =>
    this.setState({ showModal: !this.state.showModal });

  public testSendTransaction = async () => {
    const { web3, address, chainId } = this.state;

    if (!web3) {
      return;
    }
    const tx = await formatTestTransaction(address, "0xAaa646A9588Bacded6Eb9A1e77C8c29842676fDA", this.state.pricex, chainId);

    try {
      // open modal
      this.toggleModal();

      // toggle pending request indicator
      this.setState({ pendingRequest: true });

      // @ts-ignore
      function sendTransaction(_tx: any) {
        return new Promise((resolve, reject) => {
          web3.eth
            .sendTransaction(_tx)
            .once("transactionHash", (txHash: string) => resolve(txHash))
            .catch((err: any) => reject(err));
        });
      }
      console.log('tx', tx)
      // send transaction
      const result = await sendTransaction(tx);

      // format displayed result
      const formattedResult = {
        action: ETH_SEND_TRANSACTION,
        txHash: result,
        from:   address,
        to:     address,
        value: "0.09 ETH"
      };

      // display result
      this.setState({
        web3,
        pendingRequest: false,
        result: formattedResult || null
      });
    } catch (error) {
      console.error(error); // tslint:disable-line
      this.setState({ web3, pendingRequest: false, result: null });
    }
  };

  // public testSignMessage = async () => {
  //   const { web3, address } = this.state;

  //   if (!web3) {
  //     return;
  //   }

  //   // test message
  //   const message = "My email is john@doe.com - 1537836206101";

  //   // hash message
  //   const hash = hashPersonalMessage(message);

  //   try {
  //     // open modal
  //     this.toggleModal();

  //     // toggle pending request indicator
  //     this.setState({ pendingRequest: true });

  //     // send message
  //     const result = await web3.eth.sign(hash, address);

  //     // verify signature
  //     const signer = recoverPublicKey(result, hash);
  //     const verified = signer.toLowerCase() === address.toLowerCase();

  //     // format displayed result
  //     const formattedResult = {
  //       action: ETH_SIGN,
  //       address,
  //       signer,
  //       verified,
  //       result
  //     };

  //     // display result
  //     this.setState({
  //       web3,
  //       pendingRequest: false,
  //       result: formattedResult || null
  //     });
  //   } catch (error) {
  //     console.error(error); // tslint:disable-line
  //     this.setState({ web3, pendingRequest: false, result: null });
  //   }
  // };

  // public testSignPersonalMessage = async () => {
  //   const { web3, address } = this.state;

  //   if (!web3) {
  //     return;
  //   }

  //   // test message
  //   const message = "Okay bears";

  //   // encode message (hex)
  //   const hexMsg = convertUtf8ToHex(message);

  //   try {
  //     // open modal
  //     this.toggleModal();

  //     // toggle pending request indicator
  //     this.setState({ pendingRequest: true });

  //     // send message
  //     const result = await web3.eth.personal.sign(hexMsg, address);

  //     // verify signature
  //     const signer = recoverPersonalSignature(result, message);
  //     const verified = signer.toLowerCase() === address.toLowerCase();

  //     // format displayed result
  //     const formattedResult = {
  //       action: PERSONAL_SIGN,
  //       address,
  //       signer,
  //       verified,
  //       result
  //     };

  //     // display result
  //     this.setState({
  //       web3,
  //       pendingRequest: false,
  //       result: formattedResult || null
  //     });
  //   } catch (error) {
  //     console.error(error); // tslint:disable-line
  //     this.setState({ web3, pendingRequest: false, result: null });
  //   }
  // };

  // public testContractCall = async (functionSig: string) => {
  //   let contractCall = null;
  //   switch (functionSig) {
  //     case DAI_BALANCE_OF:
  //       contractCall = callBalanceOf;
  //       break;
  //     case DAI_TRANSFER:
  //       contractCall = callTransfer;
  //       break;

  //     default:
  //       break;
  //   }

  //   if (!contractCall) {
  //     throw new Error(
  //       `No matching contract calls for functionSig=${functionSig}`
  //     );
  //   }

  //   const { web3, address, chainId } = this.state;
  //   try {
  //     // open modal
  //     this.toggleModal();

  //     // toggle pending request indicator
  //     this.setState({ pendingRequest: true });

  //     // send transaction
  //     const result = await contractCall(address, chainId, web3);

  //     // format displayed result
  //     const formattedResult = {
  //       action: functionSig,
  //       result
  //     };

  //     // display result
  //     this.setState({
  //       web3,
  //       pendingRequest: false,
  //       result: formattedResult || null
  //     });
  //   } catch (error) {
  //     console.error(error); // tslint:disable-line
  //     this.setState({ web3, pendingRequest: false, result: null });
  //   }
  // };

  public resetApp = async () => {
    const { web3 } = this.state;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
    await this.web3Modal.clearCachedProvider();
    this.setState({ ...INITIAL_STATE });
  };

  public render = () => {
    const {
      assets,
      address,
      connected,
      chainId,
      fetching,
      showModal,
      pendingRequest,
      result
    } = this.state;
    return (
      <SLayout>
        <div className="__next">
          <div className="header flex">
            <div className="wapper flex center">
            <div className="social">

            </div>
          </div>
        </div>

        <Header
          connected={connected}
          address={address}
          chainId={chainId}
          killSession={this.resetApp}
        />
        <div className="title flex wapper">
          <span className="h1__span">
            <span className="color">OKAY BEARS NFT </span>
            </span>
          <span className="h2__span">
            <span className="color">Bonus: Mint 2 &amp; get 1 FREE</span>
          </span>
          <span className="h3__span"><span className="color"></span></span>
        </div>

        <div className="containers wapper flex">
          <div className="first flex">
            <div className="left"></div>
            <div className="right">
              <div className="container">
                <div className="price__block flex center">
                  <div className="avatar-eth"></div>
                  <div className="nft_price flex warp">
                <span>PRICE per NFT</span>
                <span>{this.state.price} ETH</span>
              </div>
            </div>
            <div className="pay__block flex center warp">
              <div className="first flex center">

                <SBtn className="minus odd" onClick={this.countDown}>-</SBtn>
                <div className="circle flex center">
                  <span className="num form__input">{this.state.selfCount}</span>
                </div>
                <SBtn className="plus odd" onClick={this.countUp}>+</SBtn>

                <div className="max flex center" onClick={this.countMax}>
                  <span>SET MAX</span>
                </div>

              </div>
              <div className="second">
                <div className="calc flex">
                  <span>Total</span>
                  <span className="eth_pr">
                    <strong id="eth_total">{this.state.price}</strong> ETH
                  </span>
                </div>
              </div>

              {connected ?
                <STestButton  onClick={this.testSendTransaction}>
                  MINT
                </STestButton>
                :
                <ConnectButton onClick={this.onConnect} />
              }
            </div>
          </div>
        </div>

      </div>
      <div className="second flex center">
        <div className="inner flex center">
          <div className="progress">Progress</div>
          <div className="progress-bar">
        <div className="progress-done flex" data-done="100">
          <span className="done-span"></span>
        </div>
      </div>
  </div>
</div>
</div>
  <div className="footer">
    <div className="wapper flex center">
      <span>© COPYRIGHT - 2022 OKAY BEARS NFT. ALL RIGHTS RESERVED.</span>
      </div>
    </div>
  </div>
        <Modal show={showModal} toggleModal={this.toggleModal}>
          {pendingRequest ? (
            <SModalContainer>
              <SModalTitle>{"Pending Call Request"}</SModalTitle>
              <SContainer>
                <Loader />
                <SModalParagraph>
                  {"Approve or reject request using your wallet"}
                </SModalParagraph>
              </SContainer>
            </SModalContainer>
          ) : result ? (
            <SModalContainer>
              <SModalTitle>{"Call Request Approved"}</SModalTitle>
              <ModalResult>{result}</ModalResult>
            </SModalContainer>
          ) : (
            <SModalContainer>
              <SModalTitle>{"Call Request Rejected"}</SModalTitle>
            </SModalContainer>
          )}
        </Modal>
      </SLayout>
    );
  };
}

export default App;
